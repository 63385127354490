import { useEffect, useRef, useState } from 'react';

export default function useABHook(
  message: string,
  action: Function,
  message2?: string,
  action2?: Function,
  message3?: string,
  action3?: Function
) {
  let loaded2 = false;
  let loaded3 = false;
  const [loaded, setLoaded] = useState<boolean>(false);
  const handleMessage = (e) => {
    if (e?.data == message) {
      if (!loaded && !loaded2) {
        action();
        loaded2 = true;
        setLoaded(true);
      }
    }
    if (e?.data == message2) {
      if (!loaded && !loaded2) {
        action2();
        loaded2 = true;
        setLoaded(true);
      }
    }
    if (e?.data == message3) {
      if (!loaded && !loaded3) {
        action3();
        loaded3 = true;
        setLoaded(true);
      }
    }
  };
  const cbRef = useRef(handleMessage);
  useEffect(() => {
    cbRef.current = handleMessage;
  }); // update each render

  useEffect(() => {
    const cb = (e) => cbRef.current(e); // then use most recent cb value

    window.addEventListener('message', cb);

    if ((window as any).messagebkp && (window as any).messagebkp.length > 0) {
      for (const msg of (window as any).messagebkp) {
        handleMessage({ data: msg });
      }
      (window as any).messagebkp.splice();
    }

    return () => {
      window.removeEventListener('message', cb);
    };
  }, []);
}


import React, { Fragment } from 'react';
import HighlightIcon from '../HighlightIcon';

export default function Vertical({
  highlights,
  facility,
  size = null,
  ...props
}) {
  const checkedHighlights = highlights.filter((highlight) => highlight.check);
  return (
    <>
      <div
        className="flex flex-col gap-3 justify-center items-start my-2 px-4"
        {...props}
      >
        {checkedHighlights.map((highlight, index) => (
          <Fragment key={highlight.type + index}>
            {highlight.type === 'facility_shuttle_frequency' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="shuttle"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1">
                  SHUTTLE FREQUENCY
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}
            {highlight.type === 'facility_free_shuttles' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="free-shuttle"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1">
                  FREE SHUTTLE
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}
            {highlight.type === 'facility_no_shuttles' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="no-free-shuttle"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1">
                  NO SHUTTLE
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}
            {highlight.type === 'facility_distance' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="distance"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1">
                  DISTANCE
                </p>
                {highlight.description ? (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                ) : (
                  <p>
                    {facility.facility_airport_distance}{' '}
                    {`${
                      facility.facility_airport_distance === 1
                        ? 'mile'
                        : 'miles'
                    }`}
                  </p>
                )}
              </div>
            )}
            {highlight.type === 'facility_free_cancellation' && (
              <div className="flex flex-row justify-center items-center ">
                <HighlightIcon
                  type="free-cancellations"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1">
                  FREE CANCELLATIONS
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}
            {highlight.type === 'facility_accessibility' && (
              <div className="flex flex-row justify-center items-center ">
                <HighlightIcon
                  type="accessible"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1 uppercase">
                  Wheelchair Accessible
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}
            {highlight.type === 'facility_open_hour' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="open_hour"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  stroke="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1 uppercase">
                  Facility Access
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}
            {highlight.type === 'facility_eletric_car' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="eletric_car"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1 uppercase">
                  EV Charger
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}

            {highlight.type === 'facility_additional_comments' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="additional_comments"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1 uppercase">
                  Note
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )}
            {/* {highlight.type === 'facility_extra_free_cancellation' && (
              <div className="flex flex-row justify-center items-center">
                <HighlightIcon
                  type="extra_fee_cancellation"
                  width={size === 'small' ? 22 : 38}
                  height={size === 'small' ? 22 : 38}
                  fill="#434343"
                />
                <p className="text-[0.7rem] md:text-sm mt-1 text-center font-bold mx-2 mb-1 uppercase">
                  Extra Fee Cancellation
                </p>
                {highlight.description && (
                  <p className="text-center text-[0.75rem]">
                    {highlight.description}
                  </p>
                )}
              </div>
            )} */}
          </Fragment>
        ))}
      </div>
    </>
  );
}
